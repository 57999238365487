import { Component } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: "./sign-in.component.html",
  styleUrl: "./sign-in.component.scss"
})
export class SignInComponent {
  constructor(private userService: UserService, private route: ActivatedRoute) { }
  signIn() {
    const invite = this.route.snapshot.params["slug"];
    this.userService.signInWithGoogle(JSON.stringify({ invite }));
  }
}
